import React, { Fragment } from 'react';

import { ReviewCycle } from 'models/ReviewCycle';

import can from 'helpers/can';
import { useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import {
  Button,
  Dropdown,
  DropdownDivider,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Icon,
  Testable,
} from 'components';

import AddRoleModal from './AddRoleModal';
import ArchiveCampaignItem from './ArchiveCampaignItem';
import ListAdminsModal from './ListAdminsModal';

type Props = {
  reviewCycle: ReviewCycle;
};

const OtherActionsMenuButton = ({ reviewCycle }: Props) => {
  const organization = useOrganization();
  const targetedCampaignRoleEnabled = organization.featureFlags.includes(
    'targetedCampaignRole'
  );
  const canManageRoles =
    targetedCampaignRoleEnabled &&
    can({ perform: 'manage_roles', on: reviewCycle });
  const [addRoleModalVisible, setAddRoleModalVisible] = React.useState(false);
  const [listAdminsModalVisible, setListAdminsModalVisible] =
    React.useState(false);

  return (
    <Fragment>
      <Testable name="test-other-actions-button">
        <Dropdown>
          <DropdownTrigger>
            <Button color="secondary">
              {__('Other actions')}
              <Icon name="keyboard_arrow_down" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            {canManageRoles && (
              <div>
                <DropdownMenuItem onClick={() => setAddRoleModalVisible(true)}>
                  {__('Give admin rights on the campaign')}
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => setListAdminsModalVisible(true)}
                >
                  {__(
                    'See users with administrator permissions on the campaign'
                  )}
                </DropdownMenuItem>
                <DropdownDivider />
              </div>
            )}
            <ArchiveCampaignItem reviewCycle={reviewCycle} />
          </DropdownMenu>
        </Dropdown>
      </Testable>
      {addRoleModalVisible && (
        <AddRoleModal
          reviewCycleId={reviewCycle.id}
          reviewCycleName={reviewCycle.name}
          reviewCycleType={reviewCycle.interactionType}
          onClose={() => setAddRoleModalVisible(false)}
        />
      )}
      {listAdminsModalVisible && (
        <ListAdminsModal
          reviewCycleId={reviewCycle.id}
          reviewCycleName={reviewCycle.name}
          onClose={() => setListAdminsModalVisible(false)}
        />
      )}
    </Fragment>
  );
};

export default OtherActionsMenuButton;
